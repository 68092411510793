import React, { useState } from 'react';
import logo from '../img/logo.png';

const Navbar = () => {

    return (
        <div className="navbar sticky z-50 top-0 border-white border-t-[1px] bg-[#b49666]">
            <div className="flex-1 flex">
                <img src={logo} className='w-16 invert'></img>
                <h2 className='pl-5 text-white'>Marie KNOBLOCH</h2>
            </div>
            <div className="flex-none">
                <ul className="menu menu-horizontal text-xl text-white px-1 ">
                    <li className='hover:bg-[#1b1b1b ]'><a>Bouger</a></li>
                    <li className='hover:bg-[#1b1b1b ]'><a>Manger</a></li>
                    <li className='hover:bg-[#1b1b1b ]'><a>Bien-être</a></li>
                    <li className='hover:bg-[#1b1b1b ]'><a>Budget</a></li>           
                </ul>
            </div>
        </div>

    )
   
};

export default Navbar;
