import React, { useState } from 'react';
import bouger from '../img/bouger.png';
import manger from '../img/manger.png';
import bienetre from '../img/bienetre.png';
import budget from '../img/budget.png';
import marillette from '../img/marillette.jpg';
import logo from '../img/logo.png';
import Navbar from '../component/navbar';
import relaxation from '../img/imagerelaxation.png';
import argent from '../img/imageargent.png';
import bienmanger from '../img/imagemanger.png';
import sport from '../img/imagesport.png';
import bienveillance from '../img/bienveillance.png';
import authenticite from '../img/atuhentique.png';
import serenite from '../img/serenite.png';
import evolution from '../img/evolution.png';
import efficacite from '../img/efficacite.png';



const Index = () => {
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    return (
        <div>
            <div className='flex h-screen w-full bg-black overflow-hidden'>
                <div className='flex w-3/5 h-screen'>
                    {[
                        { src: bouger, title: "Bouger" },
                        { src: manger, title: "Manger" },
                        { src: bienetre, title: "Bien-être" },
                        { src: budget, title: "Budget" }
                    ].map((item, index) => (
                        <div 
                            key={index}
                            className={`relative flex flex-col justify-end transition-all duration-500 ease-in-out h-full ${
                                hoveredIndex === index ? 'w-2/5' : 'w-1/4' // Ajustez cette partie pour le survol
                            }`}
                            style={{ flexBasis: hoveredIndex === index ? '50%' : '25%' }} // Définissez la base de flex initiale ici
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img 
                                src={item.src} 
                                className={`absolute top-0 left-0 w-full h-full object-cover transition-transform duration-500 ease-in-out ${
                                    hoveredIndex === index ? 'scale-110' : 'scale-100'
                                }`} 
                            />
                            <div className={`absolute bottom-0 w-full text-white text-center text-xl bg-black bg-opacity-50 py-2 transition-opacity duration-500 ease-in-out`}>
                                {item.title}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex w-2/5 items-center justify-center flex-col p-4'>
                    <img src={logo} className='w-1/3 h-auto object-contain mb-4 invert'></img>
                    <h1 className='text-white text-3xl'>Marie KNOBLOCH</h1>
                    <p className='text-white text-xl'>Coach sportif diplômée</p>
                </div>
            </div>
            <Navbar></Navbar>
            <div className='bg-[#1b1b1b] flex h-auto'>
                <div className='w-1/2 flex justify-center items-center bg-gradient-to-b from-[#6d6d6d] to-[#545454]'>
                    <img src={marillette} className='h-full w-auto bg-contain ' alt="Marillette"></img>
                </div>
                <div className='w-1/2 flex flex-col items-center justify-center p-12 space-y-4'>
                    <h1 className='text-white text-6xl mb-6'>Qui suis-je ?</h1>
                    <p className='text-white text-3xl leading-relaxed'>Diététicienne et coach sportive diplômée, je me suis vraiment spécialisée dans la perte de poids saine et durable ainsi que dans la remise en forme pour les personnes stressées,anxieuses.<br></br>
                    De manière générale, je suis passionnée par la prévention santé, le bien-être physique ET mental sur le long terme 🌱🧘🏼‍♀️.
                    Je propose des formules Sport & Nutrition pour un suivi complet sur 1, 3 ou 6 mois 📝<br></br>
                    → Les consultations sont totalement individualisées et personnalisées pour répondre à vos besoins et objectifs à vous 🫵🏼.</p><br></br>
                    <button className="bg-[#b49666] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-96 h-16">
                        En savoir plus sur moi
                    </button>
                </div>
            </div>
            <div  className=' h-[500px]   bg-[#eceee9] pt-20 w-full'>
                <center><h1 className='text-4xl text-black'>Mes prestations en physique</h1></center>
                <div className='flex pt-10 flex-row items-center justify-center gap-16'>
                    <div className="card rounded-none items w-96 bg-[#b49666] shadow-xl">
                        <div className="card-body items-center text-center">
                            <h2 className="card-title">Programme 1 mois rapidité</h2>
                            <div className="card-actions justify-end pt-5">
                            <a target='_blank' href="https://www.doctolib.fr/dieteticien/nancy/marie-knobloch"><button className="btn btn-primary rounded-none w-80">En savoir +</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="card rounded-none items w-96 bg-[#b49666] shadow-xl">
                        <div className="card-body items-center text-center">
                            <h2 className="card-title">Programme 4 mois efficacité</h2>
                            <div className="card-actions justify-end pt-5">
                            <a target='_blank' href="https://www.doctolib.fr/dieteticien/nancy/marie-knobloch"><button className="btn btn-primary w-80 rounded-none" >En savoir +</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="card rounded-none items w-96 bg-[#b49666] shadow-xl">
                        <div className="card-body items-center text-center">
                            <h2 className="card-title">Programme 6 mois stabilité</h2>
                            <div className="card-actions justify-end pt-5">
                            <a target='_blank' href="https://www.doctolib.fr/dieteticien/nancy/marie-knobloch"><button className="btn btn-primary w-80 rounded-none">En savoir +</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  className='h-[700px] bg-[#1b1b1b]  pt-20 w-full'>
                <center><h1 className='text-4xl text-white'>Mes programmes en ligne</h1></center>
                <div className='flex pt-10 flex-row items-center justify-center gap-16'>
                    <div className="card rounded-none w-96 bg-base-100 shadow-xl">
                        <figure className='bg-cover'><img className='h-64' src={sport} alt="sport" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Sport adapté</h2>
                            <p>Des programmes sportifs pour tous les niveaux et objectifs</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Acheter</button>
                            </div>
                        </div>
                    </div>
                
                    <div className="card rounded-none w-96 bg-base-100 shadow-xl">
                        <figure className='bg-cover'><img className='h-64' src={relaxation} alt="relaxation" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Bien-être</h2>
                            <p>Petites astuces pour se sentir mieux dans sa tête</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Acheter</button>
                            </div>
                        </div>
                </div>
                    <div className="card rounded-none w-96 bg-base-100 shadow-xl">
                        <figure className='bg-cover'><img className='h-64' src={argent} alt="sport" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Gestion financière</h2>
                            <p>Faire des économies, avoir de l'argent pour ses loisirs et pour manger équilibré </p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Acheter</button>
                            </div>
                        </div>
                </div>
                    <div className="card rounded-none w-96 bg-base-100 shadow-xl">
                        <figure className='bg-cover'><img className='h-64' src={bienmanger} alt="sport" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Manger sainement</h2>
                            <p>Des connaissances des plans alimentaires et des recettes pour prendre en main son alimentation</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Acheter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  className='min-h-[1000px] h-auto bg-[#0000] pt-20 w-full'>
                <center><h1 className='text-4xl black'>Actualités</h1></center>
                <div className='flex flex-row mt-10 gap-20 items-center justify-center flex-wrap mr-52 ml-52'>
                    <div className="card w-96 bg-blue-400 shadow-xl rounded-none">
                        <figure><img src={bouger} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Bouger !</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div>
                    <div className="card w-96 bg-red-500 shadow-xl rounded-none">
                        <figure><img src={manger} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Shoes!</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div><div className="card w-96 bg-green-400 shadow-xl rounded-none">
                        <figure><img src={bienetre} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Shoes!</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div><div className="card w-96 bg-purple-400 shadow-xl rounded-none">
                        <figure><img src={sport} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Shoes!</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div><div className="card w-96 bg-orange-400 shadow-xl rounded-none">
                        <figure><img src={argent} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Shoes!</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div><div className="card w-96 bg-pink-400 shadow-xl rounded-none">
                        <figure><img src={relaxation} alt="Shoes" /></figure>
                        <div className="card-body">
                            <h2 className="card-title">Shoes!</h2>
                            <p>If a dog chews shoes whose shoes does he choose?</p>
                            <div className="card-actions justify-end">
                            <button className="btn btn-primary">Lire la suite</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                  

            <div className='h-[500px] mt-16 bg-[#b49666] pt-20 w-full'>
            <center><h1 className='text-4xl text-black'>Mes valeurs</h1></center>
            <div className='flex flex-row gap-10 items-center justify-center'>
                <div className='flex flex-col items-center m-10'>
                    <div className='rounded-full w-52 h-52 bg-white overflow-hidden flex justify-center items-center'>
                        <img src={bienveillance} className='w-44 h-44 object-cover'></img>
                    </div>
                    <p className='mt-4 text-center font-semibold'>Bienveillance</p>
                </div>
                <div className='flex flex-col items-center m-10'>
                    <div className='rounded-full w-52 h-52 bg-white overflow-hidden flex justify-center items-center'>
                        <img src={authenticite} className='w-44 h-44 object-cover'></img>
                    </div>
                    <p className='mt-4 text-center font-semibold'>Authenticité</p>
                </div>
                <div className='flex flex-col items-center m-10'>
                    <div className='rounded-full w-52 h-52 bg-white overflow-hidden flex justify-center items-center'>
                        <img src={serenite} className='w-44 h-44 object-cover'></img>
                    </div>
                    <p className='mt-4 text-center font-semibold'>Sérénité</p>
                </div>
                <div className='flex flex-col items-center m-10'>
                    <div className='rounded-full w-52 h-52 bg-white overflow-hidden flex justify-center items-center'>
                        <img src={evolution} className='w-40 h-40 object-cover'></img>
                    </div>
                    <p className='mt-4 text-center font-semibold'>Évolution</p>
                </div>
                <div className='flex flex-col items-center m-10'>
                    <div className='rounded-full w-52 h-52 bg-white overflow-hidden flex justify-center items-center'>
                        <img src={efficacite} className='w-44 h-44 object-cover'></img>
                    </div>
                    <p className='mt-4 text-center font-semibold'>Efficacité</p>
                </div>
            </div>
        </div>
                <div className="bg-gray-100 py-8 px-6">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Contactez-moi</h2>
            <form className="max-w-4xl mx-auto space-y-4 bg-white rounded-md shadow-md p-6" action="#" method="POST">
                <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full lg:w-1/2 px-3 mb-4 lg:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first-name">Prénom</label>
                        <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="first-name" type="text" placeholder="Jean" required />
                    </div>
                    <div className="w-full lg:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last-name">Nom</label>
                        <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="last-name" type="text" placeholder="Dupont" required />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">Email</label>
                        <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="email" placeholder="email@example.com" required />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Numéro de téléphone</label>
                        <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" type="tel" placeholder="+33 6 12 34 56 78" required />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">Message</label>
                        <textarea className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-48 resize-none" id="message" placeholder="Votre message ici..." required></textarea>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-2">
                    <div className="w-full px-3">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full" type="submit">
                            Envoyer
                        </button>
                    </div>
                </div>
            </form>
            
        </div>
        <footer class="footer footer-center p-10 bg-[#b49666]  text-base-content rounded">
  <nav class="grid grid-flow-col gap-4">
    <a class="link link-hover">Index</a>
    <a class="link link-hover">Bouger</a>
    <a class="link link-hover">Manger</a>
    <a class="link link-hover">Bien-être</a>
    <a class="link link-hover">Budget</a>
  </nav> 
  <nav>
    <div class="grid grid-flow-col gap-4">
      <a href="https://www.tibria.fr/praticiens/marie-knobloch" target="_blank"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M1001 2229 c-70 -8 -221 -44 -249 -59 -9 -4 100 -99 314 -273 257 -209 333 -267 356 -267 l28 0 0 278 0 278 -47 12 c-149 38 -265 47 -402 31z m67 -247 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m234 -107 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z"/><path d="M1478 1905 l-3 -265 35 6 c34 7 362 80 468 105 28 7 52 16 52 20 0 20 -223 231 -289 274 -82 54 -222 125 -245 125 -13 0 -16 -37 -18 -265z m209 -91 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z"/><path d="M611 2109 c-382 -191 -619 -605 -598 -1041 19 -398 242 -746 596 -931 607 -317 1356 -12 1569 639 53 159 62 135 -101 265 -78 62 -227 182 -330 267 -104 85 -191 152 -194 149 -2 -3 -5 -260 -5 -571 l-1 -566 -95 0 -95 0 7 63 c3 34 9 288 13 565 l6 502 -34 -6 c-68 -12 -397 -83 -502 -108 -60 -14 -116 -26 -126 -26 -13 0 -20 15 -29 68 -7 37 -15 79 -18 93 -6 26 -5 26 83 38 99 14 570 98 592 106 8 3 -15 29 -65 69 -44 35 -191 154 -327 265 -137 110 -252 201 -257 201 -4 0 -44 -18 -89 -41z m237 -360 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-223 -524 c0 -30 -39 -27 -43 3 -3 20 0 23 20 20 15 -2 23 -10 23 -23z m433 -952 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z"/><path d="M1960 1556 c-285 -44 -393 -67 -378 -81 13 -12 438 -358 563 -458 39 -31 73 -57 77 -57 11 0 14 148 6 255 -12 162 -72 366 -107 364 -9 -1 -81 -11 -161 -23z"/></g></svg></a>
      <a target='_blank' href="https://www.doctolib.fr/dieteticien/nancy/marie-knobloch"><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24" height="24" viewBox="0 0 1200.000000 1200.000000"
 preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"fill="#000000" stroke="none"><path d="M6900 10704 c-19 -2 -98 -9 -175 -14 -237 -19 -604 -71 -875 -126
-503 -102 -1179 -319 -1556 -499 l-132 -63 11 -45 10 -45 676 -5 c658 -5 815
-11 1241 -42 948 -71 1740 -250 2295 -520 284 -138 473 -272 691 -489 118
-118 168 -177 230 -271 176 -264 271 -483 349 -810 74 -306 100 -589 92 -1000
-6 -300 -20 -468 -62 -760 -23 -155 -36 -236 -110 -641 -13 -72 -33 -171 -44
-220 -11 -49 -41 -183 -66 -299 -191 -881 -443 -1421 -876 -1880 -582 -615
-1505 -945 -2449 -875 -669 50 -1364 267 -2215 692 -740 370 -1243 698 -1558
1014 -149 151 -253 289 -323 429 -90 182 -119 351 -117 685 2 389 89 749 288
1197 l28 62 -29 24 -29 25 -100 -50 c-559 -280 -953 -862 -1070 -1578 -72
-447 106 -985 481 -1455 500 -625 1267 -1115 2135 -1364 1361 -389 3343 -225
4584 379 854 416 1501 1036 1925 1843 124 239 354 785 466 1112 133 384 273
971 333 1390 65 456 65 461 66 820 0 274 -4 367 -18 480 -81 619 -246 1063
-560 1509 -101 143 -198 255 -338 391 -155 150 -252 228 -451 361 -502 336
-1114 543 -1833 620 -140 15 -821 28 -915 18z"/>
<path d="M7200 8993 c-161 -26 -413 -168 -570 -319 -148 -143 -131 -90 -286
-880 -544 -2780 -686 -3515 -686 -3555 0 -27 7 -57 17 -72 81 -124 470 23 733
277 155 150 112 -12 431 1621 506 2587 541 2770 541 2810 0 92 -68 137 -180
118z"/>
</g>
</svg>
</a>
      <a href="https://www.facebook.com/profile.php?id=61555670011210" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
      <a href="https://www.instagram.com/marie.kbh/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg></a>
    </div>
  </nav> 
  <aside>
    <p>Copyright © 2024 - All right reserved by Marie KNOBLOCH</p>
  </aside>
</footer>






        

        </div>
    );
};

export default Index;
